import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PageWrapper from '../components/Structure/PageWrapper'
import GridCard from '../components/Atoms/GridCard'
import { FlexWrapper, OneHalf } from '../components/shared'

export default class ArticleIndex extends Component {
	render() {
		const {
			data: {
				craft: { entries }
			}
		} = this.props

		const articlesindex = entries && (
			<FlexWrapper>
				{entries.map((entry, i) => (
					<OneHalf key={i}>
						<GridCard
							entry={entry}
							imageObject={entry.featuredImage}
							cardclasses="min-h-300"
						/>
					</OneHalf>
				))}
			</FlexWrapper>
		)

		return (
			<PageWrapper
				pageTheme="dark"
				title="Articles"
				bonusContent={[articlesindex]}
			/>
		)
	}
}

export const ArticlesPageQuery = graphql`
	query ArticlesIndexQuery {
		craft {
			entries(section: [articles], limit: null) {
				__typename
				... on Craft_Articles {
					title
					uri
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
				}
			}
		}
	}
`
